export default [
    {
        name: 'base',
        data: {},
        items:
        [
            // { name: 'lennaTexture', source: '/assets/lenna.png', type: 'texture' },
            // { name: 'matcapTexture', source: '/assets/matcap.png', type: 'texture' },
            // { name: 'path', source: '/assets/path.glb', type: 'model'},
            // { name: 'baked1', source: '/assets/baked1.jpg', type: 'texture'},
        ]
    }
]