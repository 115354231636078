import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera
{
    constructor(_options)
    {
        // Options
        this.experience = new Experience()
        this.config = this.experience.config
        this.debug = this.experience.debug
        this.time = this.experience.time
        this.sizes = this.experience.sizes
        this.targetElement = this.experience.targetElement
        this.scene = this.experience.scene
        
        // Set up
        this.mode = 'debug' // defaultCamera \ debugCamera
        
        this.setInstance()
        this.setModes()
        if(this.debug)
        {
            // this.debugFolder = this.debug.addFolder({
            //     title: 'controls',
            //     expanded: true
            // })
            // this.debugFolder.addInput(this.parameters, 'autorotate').on('change', () => this.modes.debug.orbitControls.autoRotate = this.parameters.autorotate)
        }
    }
    
    setInstance()
    {
        // Set up
        this.instance = new THREE.PerspectiveCamera(25, this.config.width / this.config.height, 0.1, 100)
        this.instance.rotation.reorder('YXZ')
        
        this.scene.add(this.instance)
    }
    
    setModes()
    {
        this.modes = {}
        
        // Default
        this.modes.default = {}
        this.modes.default.instance = this.instance.clone()
        this.modes.default.instance.rotation.reorder('YXZ')
        
        // Debug
        this.modes.debug = {}
        this.modes.debug.instance = this.instance.clone()
        this.modes.debug.instance.rotation.reorder('YXZ')
        this.modes.debug.instance.position.set(0, 0, 15)
        
        this.modes.debug.orbitControls = new OrbitControls(this.modes.debug.instance, this.targetElement)
        this.modes.debug.orbitControls.enabled = true//this.modes.debug.active
        this.modes.debug.orbitControls.screenSpacePanning = false
        this.modes.debug.orbitControls.enableKeys = false
        this.modes.debug.orbitControls.zoomSpeed = 0.5
        this.modes.debug.orbitControls.enableDamping = true
        this.modes.debug.orbitControls.enablePan = false
        this.modes.debug.orbitControls.maxAzimuthAngle = 0
        this.modes.debug.orbitControls.minAzimuthAngle = 0
        this.modes.debug.orbitControls.update()
    }
    
    
    resize()
    {
        this.instance.aspect = this.config.width / this.config.height
        this.instance.updateProjectionMatrix()

        this.modes.default.instance.aspect = this.config.width / this.config.height
        this.modes.default.instance.updateProjectionMatrix()

        this.modes.debug.instance.aspect = this.config.width / this.config.height
        this.modes.debug.instance.updateProjectionMatrix()
    }

    update()
    {
        // Update debug orbit controls
        this.modes.debug.orbitControls.update()

        // Apply coordinates
        this.instance.position.copy(this.modes[this.mode].instance.position)
        this.instance.quaternion.copy(this.modes[this.mode].instance.quaternion)
        this.instance.updateMatrixWorld() // To be used in projection
        // this.instance.position.setY(5* Math.sin(this.time.elapsed/1000))
        // this.modes.debug.instance.position.setY(10 * Math.cos(this.time.elapsed * 0.001))
        // this.modes.debug.instance.position.setX(7 * Math.sin(this.time.elapsed * 0.001))
    }

    destroy()
    {
        this.modes.debug.orbitControls.destroy()
    }
}
